<template>
  <div class="tasks-wrapper">
    <section v-if="loading">
      <div class="loading">
        <hub-icon name="loading" spin size="xxlg"></hub-icon>
      </div>
    </section>
    <section v-else-if="hasTasks" class="report-wrapper">
      <div class="add-task-wrapper">
        <div class="template">
          <span v-if="workflow?.title">
            Applied template:
            <span class="template-name" :title="workflow.clientCodes.join(' | ')"
              ><a v-if="$hasPermission('workflows') && host" :href="`https://backoffice.${host}/workflows/${workflow.id}`" target="_blank">{{
                workflow.title
              }}</a
              ><span v-else>{{ workflow.title }}</span></span
            >
          </span>
        </div>
        <div>
          <hub-button variant="text" title="Create milestone" class="add-task-button" @click="onCreateMilestone">
            Create milestone
          </hub-button>
          <hub-button variant="text" title="Create task" class="add-task-button" @click="onCreateTask(null)"> Create task</hub-button>
        </div>
      </div>
      <div class="report">
        <hub-task-modal
          v-if="isReady"
          :selected="selected"
          :task-create-options="taskCreateOptions"
          @close="taskModalClosed"
          @createModalClose="onCreateTaskModalClosed"
          @edited="taskEdited"
        />

        <milestones
          :milestones="milestonesByDetailsId($route.params.id)"
          @editTask="onEditTask"
          @createTask="onCreateTask"
          @editMilestone="editMilestone"
        />
      </div>
    </section>
    <section v-else>
      <div class="no-tasks">
        <hub-task-modal
          v-if="isReady"
          :selected="selected"
          :task-create-options="taskCreateOptions"
          @close="taskModalClosed"
          @createModalClose="onCreateTaskModalClosed"
          @edited="taskEdited"
        />
        <hub-button variant="text" title="Create milestone" class="add-task-button" @click="onCreateMilestone">
          Create milestone
        </hub-button>
        <hub-button variant="text" title="Create task" class="add-task-button" @click="onCreateTask(null)">
          Create task
        </hub-button>
        <div></div>
      </div>
    </section>
    <milestone-modal
      v-if="editingMilestone"
      :invention="invention"
      :milestone="editingMilestone"
      @close="editingMilestone = null"
      @created="onMilestoneCreated"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '@/components/common/Icon.vue';
import Button from '@/components/common/Button.vue';

import TaskModal from '../tasks/TaskModal.vue';
import MilestoneModal from '../tasks/CreateMilestone.vue';
import Milestones from '../tasks/Milestones.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-task-modal': TaskModal,
    Milestones,
    MilestoneModal
  },
  async beforeRouteLeave() {
    await this.$store.dispatch('tasks/disconnect');
  },
  data() {
    return {
      isReady: false,
      selected: null,
      taskCreateOptions: null,
      workflow: null,
      isCreateModalVisible: false,
      editingMilestone: null,
      storeSubscription: null,
      host: location?.host
    };
  },
  computed: {
    ...mapGetters({
      milestonesByDetailsId: 'reports/myInventions/milestonesByDetailsId'
    }),
    ...mapState({
      invention: s => s.inventions.item,
      details: s => s.reports.myInventions.details
    }),
    loading() {
      return !this.details[this.$route.params.id] || this.details[this.$route.params.id]?.isRequestPending;
    },
    hasTasks() {
      return !this.loading && this.details[this.$route.params.id]?.milestones.length;
    }
  },
  async created() {
    this.$trackEvent(`Invention tasks opened`);

    if (this.$route.params.id && this.invention?.id !== this.$route.params.id) {
      await this.$store.dispatch('inventions/getById', this.$route.params.id);
    }

    const result = await Promise.all([
      this.$store.dispatch('tasks/connect'),
      this.$store.dispatch('reports/myInventions/resetDetails'),
      this.$store.dispatch('workflows/getByReferences', this.invention?.references)
    ]);

    this.workflow = result[2];

    await this.$store.dispatch('tasks/subscribe', { inventionId: this.$route.params.id });

    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      this.storeSubscription = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'tasks/IS_ONLINE_CHANGED') {
          if (mutation.payload.isOnline && mutation.payload.action === 'reconnect' && this.$route.params.id) {
            this.$store.dispatch('tasks/subscribe', {
              inventionId: this.$route.params.id
            });
            this.load();
          }
        }
      });
    });

    await this.load();
  },
  async unmounted() {
    await this.$store.dispatch('reports/myInventions/resetDetails');
    const inventionId = this.invention?.id || this.$route.params.id;
    if (inventionId) {
      await this.$store.dispatch('tasks/unsubscribe', { inventionId });
    }

    this.storeSubscription && this.storeSubscription();
    this.storeSubscription = null;
  },
  methods: {
    async onMilestoneCreated() {
      this.editingMilestone = null;
    },
    async load() {
      this.isReady = false;
      await this.$store.dispatch('reports/myInventions/getDetails', {
        id: this.$route.params.id
      });

      const { task: taskId } = this.$route.query;
      if (taskId) {
        const allTasks = this.details[this.$route.params.id].milestones.reduce((acc, curr) => {
          if (curr.tasks && curr.tasks.length) {
            acc.push(...curr.tasks);
          }
          return acc;
        }, []);
        const task = allTasks.find(t => t.id === taskId);

        if (task) {
          this.selected = task;
        }
      }

      this.isReady = true;
    },
    onCreateMilestone() {
      this.editingMilestone = { createNew: true };
    },
    onEditTask(task) {
      this.selected = task;

      this.$router.replace({ query: { task: task.id } });
    },
    taskModalClosed() {
      this.selected = null;
      this.taskCreateOptions = null;
      this.$router.replace({ query: {} });
    },
    onCreateTask(milestone) {
      let workflow = {};

      if (milestone) {
        workflow = milestone.workflow;
      } else {
        workflow = {
          id: this.invention.workflowId
        };
      }
      this.taskCreateOptions = {
        inventionId: this.$route.params.id,
        task: {
          createNew: true,
          assignees: [],
          workflow: {
            ...workflow,
            milestoneId: milestone?.id,
            milestoneTitle: milestone?.title,
            stepId: null
          }
        },
        tasks: milestone ? milestone.tasks : []
      };
    },
    onCreateTaskModalClosed(event) {
      this.isReady = false;
      if (event.status == 'created') {
        this.$trackEvent(`Task created using 'Inventions' report`);
      }
      this.taskCreateOptions = null;
      this.isReady = true;
      this.$router.replace({ query: {} });
    },
    async taskEdited() {
      this.$trackEvent(`Task saved using 'Inventions' report`);

      this.$router.replace({ query: {} });
      this.isReady = false;
      this.selected = null;
      this.isReady = true;
    },
    editMilestone(milestone) {
      this.editingMilestone = milestone;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
}

.no-tasks {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr max-content max-content 1fr;
  grid-gap: 20px;
  justify-content: center;
  align-content: center;
}

.add-task {
  &:hover {
    cursor: pointer;
    color: var(--theme-on-primary);
    border-radius: 15%;
    background: var(--theme-primary-accent);
  }
}
.tasks-wrapper {
  height: 100%;
  overflow: auto;
}
.add-task-wrapper {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.add-task-button {
  font-size: 0.7rem;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  height: 30px;
  &:hover {
    cursor: pointer;
    color: var(--theme-on-primary);
    background: var(--theme-primary-accent);
  }
}

.list-item-meta {
  justify-self: center;
  align-self: center;

  label {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--theme-on-surface-accent);
  }

  &.error {
    label {
      color: var(--theme-error);
    }
  }
}
.header-element {
  display: inline-block;
  *display: inline;
  vertical-align: center;
  margin-bottom: 0.5rem;
}
.report-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  padding-bottom: 0.5rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      background-color: transparent;
      margin: 1rem 0;
    }

    label {
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--theme-on-background-accent);
    }
  }

  .bold {
    font-weight: bold;
  }

  .report-loading {
    justify-self: center;
    align-self: center;
  }
  .report {
    height: 100%;
  }

  .template {
    padding: 10px;
    display: flex;
    justify-self: flex-end;
    margin-right: 10px;
    font-size: 0.7rem;
    font-weight: 500;

    .template-name {
      font-style: italic;
    }
  }
}
</style>
