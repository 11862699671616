<template>
  <hub-icon color="primary" size="md" :name="icon" class="terminal" />
</template>

<script>
import Icon from '../../common/Icon.vue';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    status: {
      type: String,
      default: null
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case 'finished':
          return 'checkbox-marked';
        case 'deleted':
          return 'cancel';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.terminal {
  cursor: auto;
  opacity: inherit;
}
</style>
